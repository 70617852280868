<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-payment-rules p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Правила оплаты
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            Правила оплаты, безопасность платежей и конфиденциальность информации
          </p>
          <p class="fs--small fw--light mb-l">
            С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.
          </p>
          <div class="divider" />
          <p class="fs--medium fw--bold mb-m">
            Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК».
          </p>
          <img class="mb-m alfa-bank" src="@/assets/img/alfa-bank.png">
          <p class="fs--medium fw--bold mb-m">
            К оплате принимаются карты VISA, MasterCard, Платежная система «Мир».
          </p>
          <img class="mb-m payment-systems" src="@/assets/img/payment-systems.png">
          <p class="mb-m">
            Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежная система «Мир» на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. На странице для ввода данных банковской карты потребуется ввести номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты. Далее вы будете перенаправлены на страницу Вашего банка для ввода 3DSecure кода, который придет к Вам в СМС. Если 3DSecure код к Вам не пришел, то следует обратится в банк выдавший Вам карту.
          </p>
          <img class="mb-m payment-secure" src="@/assets/img/payment-secure.png">
          <div class="editor">
            <p>
              Случаи отказа в совершении платежа:
            </p>
            <p>
              - банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;
            </p>
            <p>- недостаточно средств для оплаты на банковской карте;</p>
            <p>- данные банковской карты введены неверно;</p>
          </div>
          <p class="fs--medium fw--bold mb-m">
            Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.
          </p>
          <div class="editor mb-m">
            <p class="subtitle">
              Правила возврата товара:
            </p>
            <p>
              При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата регулируется правилами международных платежных систем. Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав потребителей»:
            </p>
            <p>
              - Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара — в течение семи дней;
            </p>
            <p>
              - Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;
            </p>
            <p>
              - Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;
            </p>
            <p>
              - При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;
            </p>
            <p>
              Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию на электронный адрес и оправить его вместе с приложением копии паспорта по адресу sales@clicktoid.ru
            </p>
            <p>
              Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате денежных средств» компанией ООО "КликТуАйДи".
            </p>
            <p>
              Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу info@clicktoid.ru
            </p>
            <p>
              Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения ООО "КликТуАйДи" Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.
            </p>
          </div>
          <p class="fs--medium fw--bold mb-l">
            По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться на горячую линию: 8 (800) 444-43-08.
          </p>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'

export default {
  name: 'PaymentRules',
  components: {
    MainTemplate,
    SecondaryPagesBackground
  }
}
</script>

<style lang="scss" scoped>
  .alfa-bank {
    max-width: 200px;
  }
  .payment-systems {
    max-width: 175px;
  }
  .payment-secure {
    max-width: 175px;
  }
</style>
